export function NoStoreInfoIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size || 12}
      height={props.size || 12}
      viewBox="0 0 12 12"
      {...props}
    >
      <g stroke="#f58d00" fill="none">
        <circle cx={6} cy={6} r={6} stroke="none" />
        <circle cx={6} cy={6} r={5.5} />
      </g>
      <text
        transform="translate(4.999 9)"
        fill="#f58d00"
        fontSize={8}
        fontFamily="ProductSans-Bold,Product Sans"
        fontWeight={700}
      >
        <tspan x={0} y={0}>
          {'i'}
        </tspan>
      </text>
    </svg>
  );
}
