import { useDispatch } from 'react-redux';
import { togglePromoDetailModal } from 'src/redux/actions';
import AllPromos from './PromoSections/AllPromos';
import { useFeatureLocks } from 'src/hooks/useFeatureLocks';

const AllProductPagePromos = ({ type, productId, customClasses = '' }) => {
  const dispatch = useDispatch();

  const { couponsFeatureStatus } = useFeatureLocks();
  const isCouponsFeatureLocked = !!couponsFeatureStatus?.isLocked;

  function openPromoModal(promoId) {
    dispatch(
      togglePromoDetailModal({
        show: true,
        promoId,
      })
    );
  }

  return (
    !isCouponsFeatureLocked && (
      <AllPromos
        productId={productId}
        type={type}
        openPromoModal={openPromoModal}
        customClasses={customClasses}
      />
    )
  );
};

export default AllProductPagePromos;
