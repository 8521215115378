import { getThemeColor } from 'src/utils/getThemeColor';
import { convertTo12HourFormat } from './utils';
import styled from 'styled-components';
import { IS_DESKTOP } from 'src/utils/checkClientDeviceType';

const StyledDiv = styled.div`
  &:hover {
    border-color: ${(props: any) => props.hoverColor || '#D5D5D5'};
  }
`;

const TimeSlot = ({ slot, isDisabled, isSelected, onClick }) => {
  const themeColor = getThemeColor();

  function getVriableClasses() {
    switch (true) {
      case isDisabled:
        return 'tw-border-none hover:!tw-border-none tw-pointer-events-none';
      case isSelected:
        return 'tw-border-solid tw-font-semibold tw-pointer-events-none';
      default:
        return 'tw-border-solid tw-border-[#D5D5D5] tw-cursor-pointer';
    }
  }

  function getInlineStyle() {
    switch (true) {
      case isDisabled:
        return {
          backgroundColor: '#F0F0F0',
          color: '#B2B2B2',
        };
      case isSelected:
        return {
          backgroundColor: themeColor + '0D',
          borderColor: themeColor,
          color: themeColor,
        };
      default:
        return {};
    }
  }

  return (
    <StyledDiv
      className={`tw-flex tw-justify-center tw-rounded-[4px] tw-border tw-py-[12px] ${getVriableClasses()}`}
      style={getInlineStyle()}
      hoverColor={!isDisabled && !isSelected && IS_DESKTOP ? themeColor : null}
      onClick={onClick}
    >
      {convertTo12HourFormat(slot.from)} - {convertTo12HourFormat(slot.to)}
    </StyledDiv>
  );
};

export default TimeSlot;
