import { deviceWidth } from '@/utils/deviceWidth';

const RatingStar = ({ rating }) => {
  const width = deviceWidth <= 766 ? '17px' : '21px';
  const height = deviceWidth <= 766 ? '15px' : '20px';

  if (rating < 2)
    return (
      <img
        alt="Rating 1 Star"
        src="/assets/images/red-star.svg"
        width={width}
        height={height}
      />
    );
  if (rating < 3)
    return (
      <img
        alt="Rating 2 Star"
        src="/assets/images/orange-star.svg"
        width={width}
        height={height}
      />
    );
  if (rating < 4)
    return (
      <img
        alt="Rating 3 Star"
        src="/assets/images/light-green-star.svg"
        width={width}
        height={height}
      />
    );
  if (rating < 5)
    return (
      <img
        alt="Rating 4 Star"
        src="/assets/images/green-star.svg"
        width={width}
        height={height}
      />
    );
  return (
    <img
      alt="Rating 5 Star"
      src="/assets/images/dark-green-star.svg"
      width={width}
      height={height}
    />
  );
};

export default RatingStar;
