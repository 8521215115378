import { itemIndexFromCart } from './itemIndexFromCart';

export function addScheduleInfoToCart(cartData, product, scheduleInfo) {
  const idx = itemIndexFromCart(cartData, product);
  const cartItem = cartData?.items?.[idx];

  if (scheduleInfo && cartItem) {
    cartItem.schedule_info = {
      ...(cartItem.schedule_info || {}),
      item_id: product?.id,
      ...scheduleInfo,
    };
  }
}
