import { useDispatch, useSelector } from 'react-redux';
import { setCart, setScheduleInfo } from 'src/redux/actions';
import { RootState } from 'src/redux/reducers';
import deepClone from 'src/utils/deepClone';
import { addScheduleInfoToCart } from '@/utils/addScheduleInfo';

const useItemScheduler = () => {
  const dispatch = useDispatch();

  const { cartData } = useSelector((state: RootState) => ({
    cartData: state.cartReducer,
  }));

  function setScheduleData(data) {
    dispatch(setScheduleInfo(data));
  }

  function changeSchedulingInfoAndSetCart(newSchedulingInfo, itemIndexInCart) {
    if (itemIndexInCart >= 0) {
      const newCartData = deepClone(cartData);
      const item = newCartData?.items?.[itemIndexInCart];
      if (item) {
        item.schedule_info = newSchedulingInfo;
        dispatch(setCart(newCartData));
      }
    }
  }

  return {
    setScheduleData,
    changeSchedulingInfoAndSetCart,
    addScheduleInfoToCart,
  };
};

export default useItemScheduler;
