const DetailedDescription = ({ detailedDescription, className }) => (
  <div className={className}>
    <article className="fw6 f6 mb3">More Details</article>
    <hr className="o-40 mb4" />
    <div
      dangerouslySetInnerHTML={{
        __html: detailedDescription,
      }}
    />
  </div>
);

export default DetailedDescription;
