/**
 * calculate and return discounted price and price of item
 * @param {object} product
 * @param {number} selectedVariantIndex
 * @return {object}
 */
export const getItemAmountForPDP = (product, selectedVariantIndex) => {
  const amount = {
    discounted_price: 0,
    price: 0,
    price_range: '',
  };

  if (selectedVariantIndex > -1 && product?.variants?.length) {
    amount.discounted_price = product?.variants?.[selectedVariantIndex].discounted_price;
    amount.price = product?.variants?.[selectedVariantIndex].price;
    amount.price_range =
      product?.variants?.[selectedVariantIndex]?.b2b_pricing_info?.price_range;
  } else {
    amount.discounted_price = product.discounted_price;
    amount.price = product.price;
    amount.price_range = product?.b2b_pricing_info?.price_range;
  }
  return amount;
};
