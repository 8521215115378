import store from '@/redux/store';
import styled from 'styled-components';

const storeInfo = store.getState().storeReducer.store;

export const CheckItemDeliveryContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  margin-bottom: ${(props) => (props.item_charge === undefined ? 0 : 4)}vh;
  @media screen and (max-width: 767px) {
    align-items: center;
    margin-bottom: 0px;
    padding-top: 20px;
    margin-top: 0px;
    margin-left: 18px;
    padding-bottom: ${(props) => props.pb}px;
  }
`;

export const CheckItemDeliveryHeader = styled.div`
  display: flex;
  font-size: 14px;
  margin-bottom: 8px;
  font-weight: 600;
  font-size: 14px;
  @media screen and (max-width: 767px) {
    width: 100%;
  }
`;

export const CheckItemDeliveryInputCtaContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const CheckItemDeliveryCtaContainer = styled.div`
  display: flex;
  padding: 12px;
  margin-left: -72px;
  border-radius: 4px;
  cursor: pointer;
`;

export const CheckItemDeliveryCtaText = styled.div`
  display: flex;
  font-size: 14px;
  font-weight: 600;
  color: ${storeInfo?.theme?.colors?.primary_color};
  @media screen and (max-width: 767px) {
    font-size: 12px;
  }
`;

export const CheckItemDeliveryInput = styled.input.attrs((props) => ({
  type: 'text',
  size: props.size || '1em',
}))`
  font-size: 14px;
  border: 1px solid #e8e8e8;
  border-radius: 4px;
  width: 278px;
  padding-left: 12px;
  @media screen and (max-width: 767px) {
    width: 90.67vw;
    margin-left: -30px;
  }
`;

export const CheckItemDeliveryHeaderContainer = styled.div`
  display: flex;
  @media screen and (max-width: 767px) {
    width: 100%;
  }
`;

export const SuccessErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  @media screen and (max-width: 767px) {
    width: 86vw;
    padding-bottom: ${(props) => props.pb}px;
  }
`;

export const SuccessErrorTopContainer = styled.div`
  display: flex;
`;

export const TickContianer = styled.div`
  display: flex;
`;

export const DeliveryChargeText = styled.div`
  display: flex;
  margin-left: 8px;
  font-size: 12px;
  align-items: center;
`;

export const SuccessErrorBottomContainer = styled.div`
  display: flex;
  font-size: 12px;
  margin-top: 4px;
  margin-left: 25px;
  ${(props) =>
    props?.itemDeliveryCharges <= -1 &&
    `color : red ; margin-left : 0px; margin-bottom: 15px`};
`;
