import Modal from '../Common/Modal';
import styles from './index.module.scss';

const VerifiedBuyerModal = ({ open, onClose }) => {
  return (
    <Modal
      visible={open}
      className="mobile-center-modal margin-top-modal-0"
      animation={'slideUp'}
      width={85}
      height={150}
      onClose={onClose}
    >
      <div className={styles.verifiedBuyerModal}>
        <h2 className="mv0 f16px fw8">You haven’t purchased this item</h2>
        <p className="mv0 f14px fw4 pt14px">
          We only allow verified buyers to submit reviews. If you have any concerns,
          please share them with us directly and we would look into the same.
        </p>
      </div>
      <div className={styles.okBtnContainer}>
        <button onClick={onClose}>OKAY</button>
      </div>
    </Modal>
  );
};

export default VerifiedBuyerModal;
