import { EMAIL_REGEX } from '@/utils/constants';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addNotifyProductInquiry } from '../../redux/actions';

const NotifyProduct = props => {
  const dispatch = useDispatch();
  const { storeInfo } = useSelector(state => ({
    storeInfo: state.storeReducer.store,
  }));

  const [emailInput, setEmailInput] = useState('');
  const [errorText, showErrorText] = useState(false);
  const handleInputChange = e => {
    setEmailInput(e.target.value);
    showErrorText(false);
  };

  const validateEmail = email => {
    return email.match(EMAIL_REGEX);
  };

  const handleCTABtnClick = () => {
    if (!validateEmail(emailInput)) {
      showErrorText(true);
      setEmailInput('');
      return;
    }

    const payload = {
      store_id: storeInfo.store_id,
      item_id: props?.product?.id,
      variant_id: props?.activeVariant?.variant_id,
      customer_email: emailInput,
    };
    dispatch(
      addNotifyProductInquiry(payload, () => {
        setEmailInput('');
      })
    );
  };
  return (
    <div className="notify-product-container">
      <p className="heading">This product is temporarily out of stock</p>
      <p className="subheading">
        Share your email and we will inform you when the product is in stock
      </p>
      <div className="notify-product-input-container">
        <input
          type="text"
          placeholder="Enter email address"
          className="notify-product-input-box"
          value={emailInput}
          onChange={handleInputChange}
        />
        <button className="notify-cta-btn" onClick={handleCTABtnClick}>
          Notify Me
        </button>
      </div>
      {errorText && (
        <p className="invalid-email-text" style={{ color: '#EB5757' }}>
          Please enter a valid email address
        </p>
      )}
    </div>
  );
};

export default NotifyProduct;
