import { isDeliveryPincodeBased } from '@/utils/isDeliveryPincodeBased';
import { TickFill } from '@/assets/svgExports/TickFill';
import { triggerGetItemDeliveryCharges } from '@/utils/triggerGetItemDeliveryChages';
import useIsComponentMounted from '@/utils/useIsComponentMounted';
import { getEstimatedDeliveryTime } from 'src/redux/actions';
import { useRouter } from 'next/router';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  CheckItemDeliveryContainer,
  CheckItemDeliveryCtaContainer,
  CheckItemDeliveryCtaText,
  CheckItemDeliveryHeader,
  CheckItemDeliveryHeaderContainer,
  CheckItemDeliveryInput,
  CheckItemDeliveryInputCtaContainer,
  DeliveryChargeText,
  SuccessErrorBottomContainer,
  SuccessErrorContainer,
  SuccessErrorTopContainer,
  TickContianer,
} from './CheckItemDelivery.styles';
import { ESTIMATE_DELIVERY_FLAG } from './constants';
import getItemDetailFromCart from '../../utils/getItemDetailFromCart';

/**
 * A Component In which We Can enter Pincode and
 * Check Whether the Item is Servicable or not along with
 * corressponding errors. Using in PDP and QuickView page.
 * used if delivery_type PinCode based.
 * @param {*} props
 * @returns
 */

function CheckItemDelivery(props) {
  const router = useRouter();
  const dispatch = useDispatch();
  const [pincode, setPincode] = useState('');
  const [estimatedDelivery, setEstimatedDelivery] = useState('');
  const { deliveryItemsData, storeData, cartAmount } = useSelector((state) => ({
    deliveryItemsData: state.deliveryReducer.itemData,
    storeData: state.storeReducer.store,
    cartAmount: state.cartReducer?.amount,
  }));
  const [itemDeliveryCharges, setItemDeliveryCharges] = useState(0);
  const itemId = router?.query?.pid ? router?.query?.pid : props.item_id;
  const itemName = router?.query?.pname ? router?.query?.pname : props.item_name;

  const isComponentMounted = useIsComponentMounted();
  useEffect(() => {
    setItemDeliveryCharges(
      deliveryItemsData?.item_delivery_charges[itemId]?.delivery_charges
    );
  }, [deliveryItemsData]);

  useEffect(() => {
    getDeliveryInfo();
  }, [cartAmount]);

  function handleInputChange(value, maxLength) {
    if (isNaN(value)) {
      value = value.substring(0, value.length - 1);
      value = value.replace(/\D/g, '');
      setPincode(value);
    }
    if (value.length > maxLength) {
      value = value.substring(0, maxLength);
      setPincode(value);
    } else {
      setPincode(value);
    }
  }

  function getDeliveryInfo() {
    if (pincode.length === 6 && parseInt(itemId)) {
      // keep amount as item price if item is not added in cart
      const { amount = props?.itemsPrice || 0 } = getItemDetailFromCart(parseInt(itemId));
      let payload = {
        pincode: pincode,
        pay_amount: amount,
        item_id: parseInt(itemId),
        item_name: itemName,
      };
      triggerGetItemDeliveryCharges(payload);
      if (storeData?.services?.delivery_estimate_flag === ESTIMATE_DELIVERY_FLAG.ACTIVE) {
        payload = {
          id: storeData?.store_id,
          text: pincode,
        };
        dispatch(
          getEstimatedDeliveryTime(payload, (data) => {
            setEstimatedDelivery(data);
          })
        );
      }
    }
  }

  function successErrorsCondition() {
    if (itemDeliveryCharges === 0)
      return 'Flat Delivery Charges: ' + deliveryItemsData?.other_delivery_charges;
    else if (itemDeliveryCharges > 0) {
      return 'Item Delivery Charges: ' + itemDeliveryCharges;
    } else {
      return "Sorry this product can't be delivered at this pincode";
    }
  }

  function renderSuccessErrorMessage() {
    return (
      <SuccessErrorContainer pb={props.pb}>
        {itemDeliveryCharges !== -1 && (
          <SuccessErrorTopContainer>
            <TickContianer>
              <TickFill width={17} height={17} color={'#0DBF44'} />
            </TickContianer>
            <DeliveryChargeText>Delivery Available</DeliveryChargeText>
          </SuccessErrorTopContainer>
        )}
        <>
          <SuccessErrorBottomContainer itemDeliveryCharges={itemDeliveryCharges}>
            {successErrorsCondition()}
          </SuccessErrorBottomContainer>
          {estimatedDelivery && itemDeliveryCharges !== -1 && (
            <SuccessErrorBottomContainer itemDeliveryCharges={itemDeliveryCharges}>
              {estimatedDelivery}
            </SuccessErrorBottomContainer>
          )}
        </>
      </SuccessErrorContainer>
    );
  }

  return (
    <>
      {isComponentMounted && isDeliveryPincodeBased() && (
        <CheckItemDeliveryContainer
          item_charge={itemDeliveryCharges}
          pb={props.pb}
          mb={props.mb}
        >
          <CheckItemDeliveryHeaderContainer>
            <CheckItemDeliveryHeader>Check Delivery Charges</CheckItemDeliveryHeader>
          </CheckItemDeliveryHeaderContainer>
          <CheckItemDeliveryInputCtaContainer>
            <CheckItemDeliveryInput
              placeholder="Enter Pincode"
              value={pincode}
              type="text"
              onChange={(e) => handleInputChange(e.target.value, 6)}
            />
            <CheckItemDeliveryCtaContainer onClick={getDeliveryInfo}>
              <CheckItemDeliveryCtaText>Check</CheckItemDeliveryCtaText>
            </CheckItemDeliveryCtaContainer>
          </CheckItemDeliveryInputCtaContainer>
          {itemDeliveryCharges !== undefined && renderSuccessErrorMessage()}
        </CheckItemDeliveryContainer>
      )}
    </>
  );
}

export default CheckItemDelivery;
