import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSatisfiedBogoPromos } from 'src/redux/actions';
import { RootState } from 'src/redux/reducers';
import BogoPromoStripPDP from '../BogoPromoStripPDP';
import { useFeatureLocks } from 'src/hooks/useFeatureLocks';
import { IS_DESKTOP } from 'src/utils/checkClientDeviceType';
import { debounce } from 'src/utils/debounce';

function getEligibleBogoPromos(payload, dispatch, callback) {
  dispatch(getSatisfiedBogoPromos(payload, callback));
}

const debounceGetSatisfiedBogoPromos = debounce(getEligibleBogoPromos, 300);

const SatisfiedBogoPromos = ({
  type = null,
  productId,
  showBottomCTA = false,
  customClasses = '',
}) => {
  const dispatch = useDispatch();

  const [promos, setPromos] = useState([]);

  const { cartData, latestSyncedCart, storeId } = useSelector((state: RootState) => ({
    cartData: state.cartReducer,
    latestSyncedCart: state.commonReducer.latestSyncedCart,
    storeId: state.storeReducer.store?.store_id,
  }));

  const { couponsFeatureStatus } = useFeatureLocks();
  const isCouponsFeatureLocked = !!couponsFeatureStatus?.isLocked;

  useEffect(() => {
    if (latestSyncedCart && latestSyncedCart?.items?.length && storeId) {
      const payload = {
        current_product_id: productId,
        cart: cartData,
      };
      debounceGetSatisfiedBogoPromos(payload, dispatch, (data) => setPromos(data));
    }
  }, [latestSyncedCart, storeId]);

  return (
    !isCouponsFeatureLocked &&
    !!promos.length &&
    !!cartData?.items?.length && (
      <div className={`tw-flex tw-w-full tw-flex-col md:tw-pt-[24px] ${customClasses}`}>
        {promos?.map(
          (
            {
              id,
              heading,
              description,
              total_count,
              current_count,
              success_text,
              is_applicable,
              is_same,
            },
            index
          ) => (
            <BogoPromoStripPDP
              key={index}
              heading={heading}
              description={description}
              total={total_count}
              available={current_count}
              route={`/offer-page?promo_id=${id}&is_reward=1`}
              showBottomCTA={showBottomCTA || !IS_DESKTOP}
              successText={success_text}
              isApplicableStatus={is_applicable}
              isXandYSame={is_same}
            />
          )
        )}
      </div>
    )
  );
};

export default SatisfiedBogoPromos;
