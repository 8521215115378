import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { DateCalendar, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import BasicModal from 'src/components/BasicModal';
import { SCREENS, TIME_RANGE_TYPES } from './constants';
import { formatDate, parseFormattedDate } from './utils';
import { InfoOutlined } from '@mui/icons-material';
import moment from 'moment';
import TimeSlot from './TimeSlot';
import { getThemeColor } from 'src/utils/getThemeColor';

const ScheduleSelectionModal = ({
  scheduleInfo,
  setScheduleInfo,
  schedualerConstraints,
  onClose,
}) => {
  const themeColor = getThemeColor();

  const [dateInfoLocal, setDateInfoLocal] = useState(null);
  const [slotInfoLocal, setSlotInfoLocal] = useState(null);
  const [activeScreen, setActiveScreen] = useState(SCREENS.DATE);
  const [showError, setShowError] = useState(false);

  const isToday = dateInfoLocal?.isSame(moment(), 'day');

  const hasSlots = !!schedualerConstraints?.ranges?.length;

  useEffect(() => {
    if (scheduleInfo) {
      setSlotInfoLocal(scheduleInfo?.time || null);
      setDateInfoLocal(dayjs(parseFormattedDate(scheduleInfo?.date)));
      scheduleInfo?.time?.from && scheduleInfo?.time?.to && toggleActiveScreen();
    }
  }, [scheduleInfo]);

  function getScreenHeading() {
    return activeScreen === SCREENS.DATE ? 'Select a date' : 'Select a time slot';
  }

  function isSlotAfter2Hrs(slot) {
    const slotStartTime = moment(slot.from, 'HH:mm:ss');
    return slotStartTime.isAfter(moment().add(2, 'hours'));
  }

  function getMaxSelectableDateInMS(today: Date) {
    return schedualerConstraints.days_range.type === TIME_RANGE_TYPES.DAY
      ? today.setDate(today.getDate() + schedualerConstraints?.days_range?.value)
      : today.setMonth(today.getMonth() + schedualerConstraints?.days_range?.value);
  }

  function getMinSelectableDateInMS(today: Date) {
    const slots = schedualerConstraints?.ranges || [];
    const isAnySlotAfter2Hrs = slots.some(isSlotAfter2Hrs);
    return isAnySlotAfter2Hrs ? today : today.setDate(today.getDate() + 1);
  }

  function toggleActiveScreen() {
    setActiveScreen(activeScreen ? 0 : 1);
  }

  function handleDateChange(newDate) {
    if (hasSlots) {
      setDateInfoLocal(newDate);
      toggleActiveScreen();
    } else {
      setScheduleInfo({
        date: formatDate(new Date(newDate)),
        time: null,
      });
      onClose();
    }
  }

  function handleSaveSchedulingInfo() {
    if (hasSlots && !slotInfoLocal) {
      setShowError(true);
    } else {
      setScheduleInfo({
        date: formatDate(new Date(dateInfoLocal)),
        time: slotInfoLocal,
      });
      onClose();
    }
  }

  function renderAllTimeSlots() {
    return (
      <span className="tw-grid tw-grid-cols-1 tw-gap-x-[20px] tw-gap-y-[12px] tw-text-[14px]/[17px] md:tw-grid-cols-2">
        {schedualerConstraints?.ranges?.map((slot, index) => {
          return (
            <TimeSlot
              key={index}
              slot={slot}
              isDisabled={isToday && !isSlotAfter2Hrs(slot)}
              isSelected={
                slotInfoLocal?.from === slot?.from && slotInfoLocal.to === slot?.to
              }
              onClick={() => {
                setShowError(false);
                setSlotInfoLocal(slot);
              }}
            />
          );
        })}
      </span>
    );
  }

  function renderErrorMessage() {
    return (
      <span className="tw-flex tw-items-center tw-gap-[10px] tw-text-[14px]/[17px] tw-font-medium tw-text-[#BC241D]">
        <InfoOutlined color="inherit" fontSize="small" /> Please select a time slot to
        schedule a booking
      </span>
    );
  }

  function renderSaveButton() {
    return (
      <div className="tw-absolute tw-bottom-[0] tw-left-[0] tw-right-[0] tw-bg-white tw-p-[16px] tw-pb-[16px] md:tw-p-[24px] ">
        <div
          className={`tw-flex tw-cursor-pointer tw-justify-center tw-rounded-[4px] tw-py-[12px] tw-text-[14px]/[17px] tw-text-white`}
          onClick={handleSaveSchedulingInfo}
          style={{
            backgroundColor: slotInfoLocal ? themeColor : '#00000066',
          }}
        >
          Save date and slot
        </div>
      </div>
    );
  }

  return (
    <BasicModal
      show
      onClose={onClose}
      heading={getScreenHeading()}
      customClasses="md:!tw-max-w-[480px] !tw-min-w-fit !tw-h-[500px]"
      headingClasses="!tw-border-none !tw-pt-[32px] !tw-px-[16px] md:!tw-px-[24px] tw-text-[24px]/[28px]"
      customCloseIcon={
        <HighlightOffIcon
          className="tw-absolute tw-right-[16px] tw-top-[16px] tw-flex-1 tw-cursor-pointer"
          onClick={onClose}
        />
      }
    >
      <div className="tw-flex-1 tw-overflow-scroll tw-px-[16px] tw-pb-[88px] tw-pt-[16px] md:tw-px-[24px] md:tw-pb-[32px]">
        {activeScreen === SCREENS.DATE ? (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateCalendar
              className="md:tw-scale-110"
              minDate={dayjs(new Date(getMinSelectableDateInMS(new Date())))}
              maxDate={dayjs(new Date(getMaxSelectableDateInMS(new Date())))}
              disableHighlightToday
              value={dateInfoLocal}
              onChange={handleDateChange}
            />
          </LocalizationProvider>
        ) : (
          <div className="tw-flex tw-flex-1 tw-flex-col tw-gap-[32px]">
            <div className="tw-flex tw-flex-col tw-gap-[12px] tw-text-[16px]/[20px] ">
              <div className="tw-flex tw-justify-between tw-font-semibold">
                <span>Date: </span>
                <span
                  className="tw-cursor-pointer"
                  style={{ color: themeColor }}
                  onClick={toggleActiveScreen}
                >
                  Edit date
                </span>
              </div>
              <span className="tw-font-normal tw-text-[#6B6B6B]">
                {formatDate(new Date(dateInfoLocal))}
              </span>
            </div>
            <div className="tw-flex tw-flex-col tw-gap-[12px] tw-text-[16px]/[20px]">
              <div className="tw-flex tw-justify-between tw-font-semibold">
                Select a time slot
              </div>
              {renderAllTimeSlots()}
            </div>
            {showError && renderErrorMessage()}
            {renderSaveButton()}
          </div>
        )}
      </div>
    </BasicModal>
  );
};

export default ScheduleSelectionModal;
