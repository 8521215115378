import router from 'next/router';
import {
  StripContainer,
  StripCTA,
  StripIconInfoWrapper,
  StripOfferIcon,
} from '../Common';
import { getRoute } from 'src/utils/routes';
import { useSelector } from 'react-redux';
import { RootState } from 'src/redux/reducers';
import { useState } from 'react';
import { TRIGGERED_PROMO_STATUSES } from 'src/constants/common';

const TrimmedString = ({ string }) => {
  const [showLess, setShowLess] = useState(true);
  return (
    <>
      {string?.slice(0, showLess ? 100 : 200)}...
      <span
        className="tw-cursor-pointer tw-text-[13px]/[24px] tw-font-semibold"
        onClick={() => setShowLess(!showLess)}
      >
        {showLess ? 'More' : 'Less'}
      </span>
    </>
  );
};

const AvailOfferCTA = ({ onClick, isDisabled, customClasses = '' }) => {
  return (
    <span className="tw-flex tw-text-nowrap">
      <StripCTA
        onClick={onClick}
        ctaTextOrIcon="Avail Offer"
        isDisabled={isDisabled}
        customClasses={customClasses}
      />
    </span>
  );
};

const BogoPromoStripPDP = ({
  heading = '',
  description = '',
  total,
  available,
  route,
  showBottomCTA = false,
  successText,
  isApplicableStatus,
  isXandYSame = false,
}) => {
  const domain = useSelector((state: RootState) => state.storeReducer.store.domain);

  const onAvailOffer = () => {
    router.push(getRoute(route, domain));
  };

  const renderDescription = (desc) => {
    if (desc.length < 100) {
      return desc;
    } else {
      return <TrimmedString string={desc} />;
    }
  };

  return (
    <StripContainer
      customClasses={`!tw-flex-row tw-justify-between tw-items-center md:tw-items-start !tw-gap-[32px] tw-p-[16px] md:tw-px-0`}
    >
      <StripIconInfoWrapper>
        <StripOfferIcon />
        <div className="tw-flex tw-flex-col tw-gap-[2px]">
          <p
            className={`tw-m-0 tw-text-[13px]/[18px] tw-font-medium md:tw-text-[14px]/[20px] md:tw-font-semibold`}
          >
            {heading}
          </p>

          {!!description && (
            <p className={`tw-m-0 tw-text-[13px]/[20px] tw-font-normal`}>
              {renderDescription(description)}
            </p>
          )}

          <p className="tw-m-0 tw-flex tw-gap-[10px] tw-text-[12px]/[24px] tw-font-medium tw-text-[#737373]">
            Item added {available}/{total}
            {!!successText && (
              <span className="te-text-[12px]/[24px] tw-font-medium tw-text-[#24AC21]">
                {successText}
              </span>
            )}
          </p>
          {showBottomCTA && !isXandYSame && (
            <AvailOfferCTA
              onClick={onAvailOffer}
              isDisabled={
                isApplicableStatus === TRIGGERED_PROMO_STATUSES.PROMO_X_AND_Y_SATISFIED
              }
              customClasses="!tw-ml-0 !tw-mt-[8px]"
            />
          )}
        </div>
      </StripIconInfoWrapper>

      {!showBottomCTA && !isXandYSame && (
        <AvailOfferCTA onClick={onAvailOffer} isDisabled={total === available} />
      )}
    </StripContainer>
  );
};

export default BogoPromoStripPDP;
